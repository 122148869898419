import { render, staticRenderFns } from "./minecraft-profile.vue?vue&type=template&id=032aa0f2&lang=pug&"
import script from "./minecraft-profile.vue?vue&type=script&lang=ts&"
export * from "./minecraft-profile.vue?vue&type=script&lang=ts&"
import style0 from "./minecraft-profile.vue?vue&type=style&index=0&id=032aa0f2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Steve: require('/app/components/Steve.vue').default})
