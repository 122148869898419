
import Vue from 'vue'
import { get_error_message, hashObject, noWait } from '~/utils/misc'
import { TextRule } from '~/types'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'SignUp',
  layout: 'form',
  middleware: 'auth',
  auth: 'guest',
  data () {
    return {
      step: 0,
      showPassword: false,
      showPasswordRepeat: false,
      form: {
        value: false,
        username: '',
        email: '',
        password: '',
        passwordRepeat: '',
        invitedBy: '',
        // subscribe: true,
        agree_tos: false,
        reachedRegulationsEnd: false,
        token: '',
        code: ''
      }
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.signUp')
    }
  },
  computed: {
    usernameRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.signUp.rules.username.empty'),
        (v: string) => v.length >= 3 || this.$t('pages.signUp.rules.username.short'),
        (v: string) => v.length <= 16 || this.$t('pages.signUp.rules.username.long'),
        (v: string) => !v.search(/^[a-zA-Zа-яА-ЯёË\d\-_]/) || this.$t('pages.signUp.rules.username.malformed1'),
        (v: string) => !v.search(/^[a-zA-Zа-яА-ЯёË\d\-_][a-zA-Zа-яА-ЯёË\d\-_.]{1,22}[a-zA-Zа-яА-ЯёË\d\-_]$/) || this.$t('pages.signUp.rules.username.malformed2')
      ]
    },
    emailRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.signUp.rules.email.empty'),
        (v: string) => v.length >= 4 || this.$t('pages.signUp.rules.email.short'),
        (v: string) => v.length <= 254 || this.$t('pages.signUp.rules.email.long'),
        (v: string) => !!~v.indexOf('@') || this.$t('pages.signUp.rules.email.malformed'),
        (v: string) => (v.indexOf('@') >= 1 && v.indexOf('@') < v.length) || this.$t('pages.signUp.rules.email.malformed')
      ]
    },
    passwordRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.signUp.rules.pass.empty'),
        (v: string) => v.length >= 8 || this.$t('pages.signUp.rules.pass.short'),
        (v: string) => v.length <= 256 || this.$t('pages.signUp.rules.pass.long')
      ]
    },
    passwordRepeatRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.signUp.rules.pass2.empty'),
        (v: string) => v === this.form.password || this.$t('pages.signUp.rules.pass2.mismatch')
      ]
    },
    progressUsernameValue (): number {
      return Math.min(100, 100 * this.form.username.length / 16)
    },
    progressUsernameColor (): string {
      for (const rule of this.usernameRules) {
        if (rule(this.form.username) !== true) {
          return 'error'
        }
      }
      return 'success'
    },
    progressEmailValue (): number {
      return Math.min(100, 100 * this.form.email.length / 254)
    },
    progressEmailColor (): string {
      for (const rule of this.emailRules) {
        if (rule(this.form.email) !== true) {
          return 'error'
        }
      }
      return 'success'
    },
    progressPasswordValue (): number {
      return Math.min(100, 100 * this.form.password.length / 64)
    },
    progressPasswordColor (): string {
      const p = this.form.password
      for (const rule of this.passwordRules) {
        if (rule(p) !== true) {
          return 'error'
        }
      }
      return p.length <= 12 || p.match(/^\d+$/) ? 'warning' : 'success'
    },
    progressPasswordRepeatValue (): number {
      return Math.min(100, 100 * this.form.passwordRepeat.length / 64)
    },
    progressPasswordRepeatColor (): string {
      for (const rule of this.passwordRepeatRules) {
        if (rule(this.form.passwordRepeat) !== true) {
          return 'error'
        }
      }
      return 'success'
    }
  },
  created () {
    const redirectTo = this.$route.query['redirect-to']
    if (typeof redirectTo !== 'undefined') {
      this.$cookies.set('redirectTo', redirectTo)
    }
    this.$nextTick(() => {
      const hash = hashObject()
      if ('token' in hash && !hash.token.search(/^[a-zA-Z\d]{32,}$/)) {
        this.form.token = hash.token
        this.form.agree_tos = true
        this.form.reachedRegulationsEnd = true
        this.step = 2
        if ('code' in hash && !hash.code.search(/^\d{6}$/)) {
          this.form.code = hash.code
        }
      }
    })
  },
  methods: {
    goBack () {
      this.step = 0
    },
    async onClickToS () {
      if (this.form.agree_tos || this.form.reachedRegulationsEnd) {
        return
      }
      try {
        const res = await this.$makeSure({
          text: this.$t('pages.signUp.ToS.popup.text'),
          confirm: this.$t('pages.signUp.ToS.popup.confirm'),
          cancel: this.$t('pages.signUp.ToS.popup.cancel')
        })
        if (res) {
          this.form.reachedRegulationsEnd = true
          this.form.agree_tos = true
          return true
        }
      } catch (e: any) {
      }
      return false
    },
    onSubmit () {
      if (!this.form.value) {
        return
      }
      // if (this.form.agree_tos) {
      //   await this.onSubmitRegulations()
      // } else {
      //   this.step = 1
      // }
      this.step = 1
    },
    async onSubmitRegulations () {
      if (!this.form.agree_tos) {
        const tos = await this.onClickToS()
        if (!tos) {
          return
        }
      }
      try {
        const res = await this.$loader(this.signUp(), {
          timeout: 0,
          immediate: true
        })
        if (res === true) {
          await this.onSuccess()
        } else if (res !== false) {
          this.form.token = res
          this.step = 2
        }
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
        this.step = 0
      }
    },
    async onSubmitCode (): Promise<void> {
      if (!this.form.token || !this.form.code) {
        await this.$makeAware({ timeout: 1500 })
        return
      }
      try {
        const res = await this.$loader(this.signUpConfirm(), {
          timeout: 0,
          immediate: true
        })
        if (res) {
          console.debug('Confirmation succeeded. Calling .onSuccess')
          await this.onSuccess()
          return
        }
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
      }
      this.form.code = ''
    },
    onRegulationsScroll (event: Event) {
      if (this.form.reachedRegulationsEnd) {
        return
      }
      const t = event.target as HTMLElement
      if (t.scrollHeight - t.clientHeight <= t.scrollTop) {
        this.form.reachedRegulationsEnd = true
      }
    },
    async onSuccess () {
      await this.$makeHappy({
        text: this.$t('messages.signUpSuccess'),
        timeout: 2000
      })
      const redirectTo = this.$cookies.get('redirectTo') || this.localePath('/minecraft-profile')
      this.$cookies.remove('redirectTo')
      this.$go(redirectTo)
    },
    async signUp (): Promise<boolean | string> {
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: 'Already authorized',
          timeout: 3000
        })
        this.$go('/')
        return true
      }
      try {
        const res = await this.$axios.post(
          '/me/auth/sign-up',
          {
            email: this.form.email,
            username: this.form.username,
            password: this.form.password,
            agree_tos: this.form.agree_tos,
            agree_mailing: false,
            promo: this.$cookies.get('PROMO_CODE') || null
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        await this.$auth.reset()
        await this.$auth.setUserToken(res.data.access_token, res.data.refresh_token)
        return this.$auth.loggedIn
      } catch (e: any) {
        if (e?.response?.status === 401) {
          return e.response.data.error.token
        }
        throw e
      }
    },
    async signUpConfirm (): Promise<boolean> {
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: 'Already authorized',
          timeout: 3000
        })
        this.$go('/')
        return false
      }
      try {
        const res = await this.$loader(this.$axios.post(
          '/me/auth/sign-up/confirm',
          {
            token: this.form.token,
            code: this.form.code
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ), {
          timeout: 1000,
          immediate: true
        })
        await this.$auth.reset()
        await this.$auth.setUserToken(res.data.access_token, res.data.refresh_token)
        if (!this.$auth.loggedIn) {
          noWait(this.$makeAware())
        }
        return this.$auth.loggedIn
      } catch (e: any) {
        console.error(e)
        console.debug('Failed to confirm SignUp')
        throw e
      }
    }
  }
})
