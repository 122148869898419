
import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLScgk49e71tLvAvHLAUfO4nHP2s1YBYWxBbXovq_qck6uupX_Q/viewform'
    }
  }
})
