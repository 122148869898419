import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=4c8a2e02&scoped=true&lang=pug&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=4c8a2e02&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8a2e02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LootboxPrize: require('/app/components/lootbox/lootbox-prize.vue').default})
